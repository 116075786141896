<template>
  <v-row class="ml-5">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">ユーザ追加</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="pa-0" align="center">
                  <v-avatar size="64" :color="iconcolor" v-model="iconcolor">
                    <v-icon x-large dark> mdi-account-circle </v-icon>
                  </v-avatar>
                </v-col>
                <v-col align="center"
                  >色変更<v-icon @click="getColor()">
                    mdi-reload
                  </v-icon></v-col
                >

                <v-col cols="12" class="pa-0">
                  <v-text-field
                    label="ID"
                    v-model="id"
                    :rules="idRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    label="名前"
                    v-model="name"
                    :rules="nameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-alert color="red lighten-2" dark v-show="msg">
              {{ msg }}
            </v-alert>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clear()">
            キャンセル
          </v-btn>
          <v-btn color="blue darken-1" text @click="regist()"> 登録 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import getRandomColor from "../../func/getRandomColor";

export default {
  data() {
    return {
      msg: "",
      dialog: false,
      iconcolor: "",
      valid: true,
      id: "",
      name: "",
      idRules: [
        (v) => !!v || "入力してください",
        (v) =>
          /@systemfloor.co.jp/.test(v) ||
          "「@systemfloor.co.jp」のメールアドレスを登録してください",
      ],
      nameRules: [(v) => !!v || "入力してください"],
    };
  },
  mounted() {
    this.getColor();
  },
  methods: {
    getColor() {
      this.iconcolor = getRandomColor();
    },
    regist() {
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/regist-user", {
            id: this.id.trim(),
            name: this.name,
            iconcolor: this.iconcolor,
          })
          .then((response) => {
            if (response.data.result == "OK") {
              this.clear();
              this.$emit("my-click");
            } else {
              this.msg = response.data.msg;
            }
          });
      }
    },
    clear() {
      this.dialog = false;
      this.getColor();
      this.id = "";
      this.name = "";
      this.msg = "";
    },
  },
};
</script>