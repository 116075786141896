import colors from "vuetify/lib/util/colors";
const basecolorList = [
    "red",
    "pink",
    "purple",
    "deepPurple",
    "indigo",
    "blue",
    "lightBlue",
    "cyan",
    "teal",
    "green",
    "lightGreen",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deepOrange",
    "brown",
    "blueGrey",
    "grey",
    "shades",
];
const detealcolorList = [
    "base",
    "lighten5",
    "lighten4",
    "lighten3",
    "lighten2",
    "lighten1",
    "darken1",
    "darken2",
    "darken3",
    "darken4",
    "accent1",
    "accent2",
    "accent3",
    "accent4",
]

function getColor() {


    var basecolorIndex = Math.floor(Math.random() * basecolorList.length);

    const basecolor = basecolorList[basecolorIndex];
    var detealcolorIndex = Math.floor(
        Math.random() * detealcolorList.length
    );
    const detealcolor = detealcolorList[detealcolorIndex];
    if (colors[basecolor][detealcolor]) {
        return colors[basecolor][detealcolor];
    } else {
        getColor();
    }
}
export default getColor
