<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-delete-outline </v-icon>
      </template>

      <v-card>
        <v-card-title> </v-card-title>

        <v-card-text> {{ id }} <br />を削除しますか？ </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="send()"> はい </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    send() {
      this.dialog = false;
      this.$emit("my-click");
    },
  },
};
</script>