<template>
  <v-container fluid class="down-top-padding">
    <v-overlay :value="notUsrmngAuth" absolute>
      <v-card>
        <v-card-title>アクセス権限がありません</v-card-title>
      </v-card>
    </v-overlay>
    <template>
      <v-card elevation="1">
        <v-card-title>
          ユーザ一覧
          <AddUser @my-click="getUserlist()"></AddUser>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ID/名前"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :sort-by="['updatetime']"
          :sort-desc="[true]"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
        >
          <template v-slot:body="{ items, headers }">
            <tbody name="list" is="transition-group" v-if="items.length > 0">
              <tr v-for="item in items" :key="item.id" class="item-row">
                <td>
                  <v-icon :color="item.iconcolor" large
                    >mdi-account-circle</v-icon
                  >
                </td>
                <td>{{ item.id }}</td>
                <td>
                  <v-text-field
                    :disabled="!item.editnow"
                    :value="item.name"
                    v-model="item.name"
                  ></v-text-field>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.usrmng"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.business"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.staff"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.room"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.tsuyukusa"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-simple-checkbox
                      v-model="item.authority.photo"
                      :disabled="!item.editnow"
                      color="red"
                    ></v-simple-checkbox>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ item.updatetime.toLocaleString() }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <v-icon v-show="!item.editnow" @click="edit(item.index)">
                      mdi-pencil
                    </v-icon>
                    <v-btn
                      v-show="item.editnow"
                      fab
                      class="flash"
                      color="red"
                      dark
                      height="35"
                      width="35"
                      @click="update(item)"
                      >確定</v-btn
                    >
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    <DeleteUser
                      :id="item.id"
                      @my-click="remove(item.id)"
                    ></DeleteUser>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="headers.length" style="text-align: center">
                  データがありません
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </template></v-container
  >
</template>
    


<script>
import DeleteUser from "@/components/usrmng/DeleteUser";
import AddUser from "@/components/usrmng/AddUser";
import moment from "moment-timezone";

import axios from "axios";
export default {
  components: {
    DeleteUser,
    AddUser,
  },
  data() {
    return {
      notUsrmngAuth: false,
      search: "",
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "icon",
        },
        { text: "ID", value: "id" },
        { text: "名前", value: "name" },
        {
          text: "ユーザ管理",
          value: "usrmng",
          align: "center",
          sortable: false,
        },
        {
          text: "経営情報",
          value: "business",
          align: "center",
          sortable: false,
        },
        {
          text: "スタッフ情報",
          value: "staff",
          align: "center",
          sortable: false,
        },
        { text: "部屋管理", value: "room", align: "center", sortable: false },
        {
          text: "露草会",
          value: "tsuyukusa",
          align: "center",
          sortable: false,
        },
        { text: "写真館", value: "photo", align: "center", sortable: false },
        {
          text: "更新日",
          value: "updatetime",
          align: "center",
          sortable: true,
        },
        { text: "", sortable: false, align: "center", value: "edit" },
        { text: "", sortable: false, align: "center", value: "delete" },
      ],
      users: [],
    };
  },
  methods: {
    getUserlist() {
      axios.get(process.env.VUE_APP_API_URL + "/usermng").then((response) => {
        if (response.data.result == "OK") {
          this.users.splice(0);
          response.data.userlist.forEach((elem, index) => {
            elem.index = index;
            elem.editnow = false;

            const updatetime = moment.tz(elem.updatetime, "Asia/Tokyo");
            elem.updatetime = updatetime.format("YYYY-MM-DD HH:mm:ss");
            this.users.push(elem);
          });
        }
      });
    },
    remove(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-user", { id: id })
        .then((response) => {
          if (response.data.result == "OK") {
            this.getUserlist();
          }
        });
    },
    edit(i) {
      this.users[i].editnow = true;
    },
    update(item) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-user", {
          id: item.id,
          name: item.name,
          authority: item.authority,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.getUserlist();
          }
        });
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.notUsrmngAuth = !response.data.authority.usrmng;
        });
    },
  },
  created() {
    this.getUserlist();
    this.getUserAuthority();
  },
};
</script>
<style>
table tr:nth-child(odd) td {
  background: #9696961a;
}

.flash {
  animation: flash 1s linear infinite;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.list-move {
  transition: transform 0.5s;
}
.item-row {
  display: table-row;
}
</style>